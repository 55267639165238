const $ = require( 'jquery' );

export default  class Alert {

    static load() {
        const alertsContainer = document.getElementById('alerts-container');
        if (alertsContainer) {
            const alerts = alertsContainer.querySelectorAll('.alert');
            alerts.forEach((alert) => {
                Alert.init(alert);
            });
            const tasks = alertsContainer.querySelectorAll('[data-task-id]');
            tasks.forEach((task) => {
                const id = task.getAttribute('data-task-id');
                const status = task.getAttribute('data-task-status');
                const message = task.getAttribute('data-task-message');

                Alert.task(id, message, status);

                task.remove()
            });
        }
    }

    static show(type = 'success', message = "Success!") {
        const alertsContainer = document.getElementById('alerts-container');
        if (!alertsContainer) return;
        const alert = Alert.createElement(type, message);
        Alert.init(alert);
        alertsContainer.appendChild(alert);
    }

    static task(id, message, status){

        const alertsContainer = document.getElementById('alerts-container');
        if(!alertsContainer) return;

        let task = document.getElementById(`task-${id}`);

        if(!task) {
            switch(status){
                case 'success':
                    task = Alert.createElement('success', message);
                    break;
                case 'error':
                    task = Alert.createElement('danger', message);
                    break;
                default:
                    task = Alert.createElement('primary', message);
                    break;
            }

            task.querySelector('i').setAttribute('data-task', 'true')
            task.setAttribute('id', `task-${id}`);
            Alert.switchTask(task, status);
            task.querySelector('span').innerText = message;
            alertsContainer.appendChild(task);

        } else {
            task.querySelector('span').innerText = message;
            Alert.switchTask(task, status);
        }

    }

    static switchTask(task, status) {

        task.setAttribute("class", "");

        const i = task.querySelector('i');
        i.setAttribute('class', '');

        switch(status){
            case 'success':
                task.classList.add("alert", "slidein", 'alert-success');
                i.classList.add('fa', 'fa-check');
                break;
            case 'error':
                task.classList.add("alert", "slidein", 'alert-danger');
                i.classList.add('fa', 'fa-close');
                break;
            default:
                task.classList.add("alert", "slidein", 'alert-primary');
                i.classList.add('fa', 'fa-spinner');
                break;
        }

        if(status === 'success' || status === 'error') {
            setTimeout(() => {
                task.classList.remove('slidein');
                task.classList.add('slideout');
                setTimeout(() => {
                    task.remove();
                }, 500);
            }, 5000);
        }

    }

    static init(alert) {
        const close = alert.querySelector('i');
        if(!close) return;


        close.addEventListener('click', () => {
            alert.classList.remove('slidein');
            alert.classList.add('slideout');
            setTimeout(() => {
                alert.remove();
            }, 500);
        });

        setTimeout(() => {
            alert.classList.remove('slidein');
            alert.classList.add('slideout');
            setTimeout(() => {
                alert.remove();
            }, 500)
        }, 5000);

    }

    static createElement(type = 'success', message = "Success!") {

        switch (type) {
            case 'success':
                type = 'success';
                break;
            case 'error':
                type = 'danger';
                break;
            default:
                type = 'primary';
                break;
        }

        const alert = document.createElement('div');
        alert.classList.add("alert", "slidein", `alert-${type}`);
        alert.setAttribute('role', 'alert');
        alert.innerHTML = `<span>${message}</span><i class="fa fa-close"></i>`;
        return alert;
    }

}