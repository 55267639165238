import consumer from "./consumer"
import Alert from '../packs/Class/Alert'

consumer.subscriptions.create({channel: "AlertChannel"}, {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received({ type, message }) {
    Alert.show(type, message);
  }
});
