import consumer from "./consumer"

document.addEventListener("DOMContentLoaded", function() {

// const sessionID = document.getElementById('session-id').textContent.trim();
const sessionElement = document.getElementById('session-id');

// Check if the sessionElement exists
if (!sessionElement) {
  return;
}

const sessionID = sessionElement.textContent.trim();

consumer.subscriptions.create({channel: "SiteGenerationProgressMessagesChannel", session_id: sessionID}, {
  connected() {

   // const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
   const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');

   // Check if the csrfTokenMeta exists
   if (!csrfTokenMeta) {
     return;
   }

   const csrfToken = csrfTokenMeta.getAttribute('content');

     // Faire un appel AJAX pour notifier le serveur que l'abonnement est prêt
     fetch('/trigger_broadcast', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
    });
  },

  disconnected() {
  },

  received(data) {

    const progressMessages = document.getElementById("progress-messages");

    if (progressMessages) {
      progressMessages.textContent = (data['message']);
    }

    const progressStatus = document.getElementById("progress-status");

    if (progressStatus && data['progress'] !== undefined && data['progress'] !== null) {
      function updateProgressBar(percentage) {
        const progressBar = document.querySelector("#progress-status .progress-bar");
        const title = document.querySelector("#wait_for_site_title");
        const loader = document.querySelector(".dots-loading");

        // Mettre à jour les attributs de la barre de progression
        progressBar.style.width = percentage + "%";
        progressBar.setAttribute("aria-valuenow", percentage);
        progressBar.textContent = percentage + "%";

        if (percentage === "100") {
          progressBar.classList.add("bg-success");
          progressBar.classList.remove("progress-bar-animated");
          progressBar.classList.remove("progress-bar-striped");
          title.textContent = "Création du site terminée.";
          loader.classList.add("d-none");
        }
      }

      const receivedPercentage = data['progress'];
        updateProgressBar(receivedPercentage);
      }
    }

  });

});
