// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery')
require('./Libs/railsSortable.js')
require("channels")

import AOS from 'aos/dist/aos.js';
import 'aos/dist/aos.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'slick-carousel/slick/slick.min.js';
import flexImages from "javascript-flex-images/flex-images.min.js";
import '../src/site.scss';
import animateScrollTo from 'animated-scroll-to';

import Typed from 'typed.js';

const jQuery = require( 'jquery' );
const loadGoogleMapsApi = require('load-google-maps-api');
import bsCustomFileInput from 'bs-custom-file-input'


window.jQuery = $;
window.$ = $;


window.addEventListener('load', () => {
  AOS.init();
  startFlexImages();
  setAffixMinHeight();
  initRailsSortable();
  homeSlideMouseEffect();


  textSwitcher(".dynamic-text", [
    " référencement",
    " image de marque",
    " visibilité en ligne",
    " réputation"
  ]);

});


document.addEventListener("DOMContentLoaded", function() {
  // setAffixMinHeight();
  startFlexImages();
  HideCookiesNotice();
});



// $(document).on('DOMContentLoaded', function() {
document.addEventListener("DOMContentLoaded", function() {

  affix();
  drawGoogleMap();
  openTabFromUrl();
  AOS.init();
  startFlexImages();
  scrollToAnchor();
  loadCountDown();
  wizardFormValidation();
  acceptContestRules();


  var modalVideo = document.getElementById("modal_video");
  if (modalVideo) {
    $('#modal_video').modal('show');
  }

  $(document).ready(function() {
    if ($('body').hasClass('no-enter-submit')) {
      $(window).keydown(function(event){
        if (event.keyCode == 13) {
          event.preventDefault();
          return false;
        }
      });
    }
  });

  $(document).ready(function () {
    bsCustomFileInput.init()
  })

  $(document).ready(function(){
    $('.slick').slick({
     prevArrow: $('.slick-prev-button'),
     nextArrow: $('.slick-next-button'),
    });
  });

  $('.slick-auto-scroll').on('beforeChange', function(event, slick, currentSlide, nextSlide){
     var scrollToElement = $('#wizard-top');
      if (scrollToElement.length > 0) {
          var offsetTop = scrollToElement.offset().top;

          $("html, body").animate({
            scrollTop: offsetTop
          }, 300); // Adjust the duration as needed
        }
  });

  // FIRST SLIDE
  $('.slick-wizard').on('afterChange', function(event, slick, currentSlide) {
    if (currentSlide == 0) {
      $('.wizard-steps-nav').addClass('d-none');
      $('.start-wizard-btn').show();
      $('.wizard-next').addClass('d-none');
    } else {
      $('.wizard-steps-nav').removeClass('d-none');
      $('.start-wizard-btn').hide();
      $('.wizard-next').removeClass('d-none');
    }
  });

  // LAST SLIDE
  $('.slick-wizard').on('afterChange', function(event, slick, currentSlide) {
    if (slick.$slides.length-1 == currentSlide) {
      $('.wizard-next').hide();
      $('.create-site-button').removeClass('d-none');
    } else {
      $('.wizard-next').show();
      $('.create-site-button').addClass('d-none');
    }
  })


  $(".wizard-next-step-btn").click(function() {
    var current_slide_index = $('.slick').slick('slickCurrentSlide');

    if (current_slide_index == 1) {
      var form = $("form.wizard-form");
        var inputsToCheck = ["#form_name", "#form_zone", "#form_sector"]; // Specify the IDs of the fields to check
        for (var i = 0; i < inputsToCheck.length; i++) {
          var input = $(inputsToCheck[i]);
          if (!input[0].checkValidity()) {
            input.addClass('is-invalid');
                form.addClass('was-validated'); // Add 'was-validated' class to the form
                return;
              }
            }
          }

    // Remove 'is-invalid' class from all fields
    $("form")[0].classList.remove('was-validated');
    // $('form').find('.is-invalid').removeClass('is-invalid');

    // Move to the next slide
    $('.slick').slick('slickNext');
  });


  $('#publishModal').on('shown.bs.modal', function () {
    $.post("/users/log_action", { action_name: "publish_modal_seen" });
  })

  // Update wizard steps based on current slide
  $('.slick').on('afterChange', function(event, slick, currentSlide) {
    var phone_number = $("#form_registration_session_id").val();
    var action_name = "wizard_step_" + (currentSlide);
    $.post("log_action", { phone_number: phone_number, action_name: action_name });

    $('.wizard-step').removeClass('active');
    $('.wizard-step.go-to-slide-' + (currentSlide + 1)).addClass('active');

    // Add "completed" class to slides that are passed
    $('.wizard-step').removeClass('completed');
    $('.wizard-step').slice(0, currentSlide + 1).addClass('completed');
  });

  // Go to specific slide when wizard step is clicked
  $('.wizard-step').click(function() {
    if (!$(this).hasClass('disabled')) {
      var slideIndex = parseInt($(this).attr('class').match(/go-to-slide-(\d+)/)[1]) - 1;
      $('.slick').slick('slickGoTo', slideIndex);
    }
  });



  $('.dropdown-toggle').dropdown();

  // Slider
  $('.homepage-slider').slick({
    autoplay: true,
    autoplaySpeed: 4500
  });

  $('.webmix-slider').slick({
    autoplay: true,
    autoplaySpeed: 4500,
    fade: true
  });

  // Slider
  $('.homepage-slider-fade').slick({
    autoplay: true,
    autoplaySpeed: 4500,
    fade: true
  });

  $('.uniq-carousel').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    fade: true
  })

  $('.carousel').slick();

  $('.logo-carousel').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });



  $('.logo-carousel-6').slick({
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

  $('.testi-carousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

  $('.logistic-carousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

  $('.osmont-carousel').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

    $('.testi-carousel-4').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        dots: true
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    ]
  });

  $('.d2s-carousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
    {
      breakpoint: 768,
      settings: "unslick"
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });


  $('.text-carousel').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  });



  $('.items-carousel').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });

    $('.single-carousel').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
    ]
  });


});


$(document).ready(function() {
  // startFlexImages();
  openTabFromUrl();
});


function startFlexImages(){
  new flexImages({ selector: '.flex-images', rowHeight: 360 });
  new flexImages({ selector: '.flex-images-small', rowHeight: 180 });
}


function setAffixMinHeight() {
    var affix_min_height = $('.affix-min-height').height();
    $('.affix-min-height').css('min-height', affix_min_height);
}

function affix() {
  window.onscroll = scroll;
  function scroll () {
    var scrollTop = window.pageYOffset;
    var affix_height = $('.affix-height').height();
    if( scrollTop > affix_height ){
      $('#main-navbar').addClass('fixed-navbar');
      $('#main-navbar').addClass('fadeIn');
    }
    else
    {
      $('#main-navbar').removeClass('fixed-navbar');
      $('#main-navbar').removeClass('fadeIn');
    }
  }

}

function drawGoogleMap() {

  if( $('#gmap').length ) {

    var site_lat = parseFloat(document.getElementById('lat').value);
    var site_lng = parseFloat(document.getElementById('lng').value);
    var site_address = document.getElementById('address').value;


    loadGoogleMapsApi({ key: 'AIzaSyB5XTXHqXSA67eM8sD0zCFXul8d-3-HZos' }).then(function (googleMaps) {

      var location = {lat: site_lat, lng: site_lng};

      var map = new googleMaps.Map(document.querySelector('#gmap'), {
        center: {
          lat: site_lat,
          lng: site_lng
        },
        zoom: 14
      })

      var marker = new googleMaps.Marker({position: location, map: map});

      var infowindow = new googleMaps.InfoWindow({
        content: site_address
      });

      marker.addListener('click', function() {
        infowindow.open(map, marker);
      }, { passive: true });



    }).catch(function (error) {
      console.error(error)
    })
  }

}

function loadCountDown(){

    const updateCountDown = (element, targetDate) => {

      const remainingTime = getRemainingTime(targetDate);

      if(remainingTime.total <= 0) return true

      $(element).find('.days .value').text(remainingTime.days.toString());
      $(element).find('.hours .value').text(remainingTime.hours.toString());
      $(element).find('.minutes .value').text(remainingTime.minutes.toString());
      $(element).find('.seconds .value').text(remainingTime.seconds.toString());


      return false

    }

    $('.countdown').each((index, element) => {

      const fetchedTarget = new Date($(element).data('countdown'));
      fetchedTarget.setHours(fetchedTarget.getHours() - 1);
      const targetDate = fetchedTarget.getTime();

      $(element).addClass('d-flex aic');

      $(element).html(`
          <div class="days d-flex fc aic jcc"><span class="value">00</span><span class="label">Jours</span></div> :
          <div class="hours d-flex fc aic jcc"><span class="value">00</span><span class="label">Heures</span></div> :
          <div class="minutes d-flex fc aic jcc"><span class="value">00</span><span class="label">Minutes</span></div> :
          <div class="seconds d-flex fc aic jcc"><span class="value">00</span><span class="label">Secondes</span></div>
      `);

      const finished = updateCountDown(element, targetDate);

      if(!finished) {
        let interval = setInterval(function() {
          const finish = updateCountDown(element, targetDate);
          if(finish) clearInterval(interval);
        }, 1000)
      }
    })

}

function getRemainingTime(targetDate) {

  function padZero(value) {
    return value < 10 ? `0${value}` : value;
  }


  const now = new Date().getTime();
  const distance = targetDate - now;

  const remainingTime = {
    days: padZero(Math.floor(distance / (1000 * 60 * 60 * 24))),
    hours: padZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))),
    minutes: padZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))),
    seconds: padZero(Math.floor((distance % (1000 * 60)) / 1000)),
    total: distance
  };

  return remainingTime;
}

function openTabFromUrl() {
  if ($(window).width() > 767) {
    let url = location.href.replace(/\/$/, "");

    if (location.hash) {
      const hash = url.split("#");
      $('#dynTab a[href="#'+hash[1]+'"]').tab("show");
      url = location.href.replace(/\/#/, "#");
      history.replaceState(null, null, url);
      setTimeout(() => {
        scrollTo( $("#myTabContent") );
      }, 400);
    }

    $('a[data-toggle="tab"]').on("click", function() {
      let newUrl;
      const hash = $(this).attr("href");
      if(hash == "#home") {
        newUrl = url.split("#")[0];
      } else {
        newUrl = url.split("#")[0] + hash;
      }
      newUrl += "/";
      history.replaceState(null, null, newUrl);
    });
  } else {
    // let url = location.href.replace(/\/$/, "");
    // const hash = url.split("#");
    // setTimeout(() => {
    //   scrollTo( $("#"+hash[1]) );
    // }, 400);
  }
}


function scrollTo( target ) {
    if( target.length ) {
        $("html, body").stop().animate( { scrollTop: target.offset().top - $("#main-navbar").height() }, 500);
    }
}


function HideCookiesNotice() {

  var cookie = getCookie("HideCookiesNotice");
  if (cookie) {
    $('.cookies-notice').hide();
  }

  $('.cookies-notice').click(function(){
    console.log(document.cookie)
    $(this).fadeOut();


    document.cookie = "HideCookiesNotice=true";
    var date = new Date();
    date.setMonth(date.getMonth()+6);
    document.cookie += ("; expires=" + date.toUTCString());
  })
}


function getCookie(c_name) {
    var c_value = document.cookie,
        c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start == -1) c_start = c_value.indexOf(c_name + "=");
    if (c_start == -1) {
        c_value = null;
    } else {
        c_start = c_value.indexOf("=", c_start) + 1;
        var c_end = c_value.indexOf(";", c_start);
        if (c_end == -1) {
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
}


// function animateScrollTo(scrollToElement, options);



function scrollToAnchor(selector) {

  $('.scrollTo').on("click", function() {
    $('.navbar-collapse').collapse('hide');
    var target = $(this).data('target');
    animateScrollTo(document.querySelector(target), {verticalOffset: -100 })
  });

}

function wizardFormValidation() {
    $('form.wizard-form').submit(function(event) {

    var password = $('form.wizard-form #register_password').val();
    var passwordConfirmation = $('form.wizard-form #register_password_confirmation').val();

    if (password !== passwordConfirmation) {
      alert('Le mot de passe et la confirmation du mot de passe ne correspondent pas.');
      return false; // Prevent form submission by returning false
    }
  });
}

function acceptContestRules() {


  var checkbox = document.querySelector('input[type="checkbox"]#accept-rules');


  if (checkbox) {

    var buttonsToDisable = document.querySelectorAll('.start-wizard-btn, .wizard-next-step-btn');

    function toggleControls() {
      buttonsToDisable.forEach(function(button) {
        if (checkbox.checked) {
          // Enable button
          button.classList.remove('disabled');
          button.addEventListener('click', preventDefaultAction);
        } else {
          // Disable button
          button.classList.add('disabled');
          button.removeEventListener('click', preventDefaultAction);
        }
      });
    }

    function toggleSlideControls() {
        $('.wizard-step').each(function() {
          if (checkbox.checked) {
            $(this).removeClass('disabled');
          } else {
            $(this).addClass('disabled');
          }
      });
    }

    function preventDefaultAction(event) {
      event.preventDefault();
    }

    // Call the function initially to set the initial state
    toggleControls();
    toggleSlideControls();

    // Add event listener to the checkbox to toggle button and slick control states
    checkbox.addEventListener('change', toggleSlideControls);
    checkbox.addEventListener('change', toggleControls);
  } else {

  }
}


function initRailsSortable() {
  $(".sortable").railsSortable();
}

function textSwitcher(selector, wordList) {
  if (document.querySelector(selector)) {
    const options = {
        strings: wordList,
        typeSpeed: 50,
        backSpeed: 10,
        // fadeOut: true,
        loop: true,
        // startDelay: 2500,
        backDelay: 2500,
        cursorChar: ''
    };
    const typed = new Typed(selector, options);
  }
}


function homeSlideMouseEffect() {
  let constrain = 150;
  let mouseOverContainer = document.getElementById("home-top");
  let ex1Layer = document.getElementById("me1-layer");

  if (mouseOverContainer && ex1Layer) {
    function transforms(x, y, el) {
      let box = el.getBoundingClientRect();
      let calcX = -(y - box.y - (box.height / 2)) / constrain;
      let calcY = (x - box.x - (box.width / 2)) / constrain;

      return "perspective(1000px) "
      + "   rotateX("+ calcX +"deg) "
      + "   rotateY("+ calcY +"deg) ";
    };

    function transformElement(el, xyEl) {
      el.style.transform  = transforms.apply(null, xyEl);
    }

    mouseOverContainer.onmousemove = function(e) {
      let xy = [e.clientX, e.clientY];
      let position = xy.concat([ex1Layer]);

      window.requestAnimationFrame(function(){
        transformElement(ex1Layer, position);
      });
    };
  }
}


// Attach the function to the window object
window.initRailsSortable = initRailsSortable;