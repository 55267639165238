import consumer from "./consumer"
import Alert from '../packs/Class/Alert'

consumer.subscriptions.create("TaskChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received({ id, message, status, args = null }) {
    console.log('tast yo');
    Alert.task(id, message, status, args);
  }
});
